<template>
  <section :class="$style.contract">
    <Title position="center">Публичная оферта</Title>
    <router-link to="/" exact>
      <img :class="$style.arrow" src="@/assets/icons/arrow.svg" />
    </router-link>
    <h2 :class="$style.subtitle">
      Настоящая публичная оферта является предложением оферента
    </h2>
    <div :class="$style.content">
      <p :class="$style.content__title">Общие положения</p>
      <p :class="$style.content__text">
        1.1. Настоящая оферта является официальным предложением ФОП Шурханов
        Юрий, далее - «Продавец», заключить Договор купли-продажи товаров (в
        т.ч. электронных) дистанционным способом, то есть через
        Интернет-магазин, далее - «Договор», и размещает публичную оферту
        (предложение) на официальном интернет-сайте Продавца
        <a :class="$style.link" href="https://invest-factory.org/">
          https://invest-factory.org/
        </a>
        (далее -« Интернет-сайт »). <br />
        1.2. Моментом полного и безусловного принятия Покупателем предложения
        продавца (акцептом) заключить электронный договор купли-продажи товаров,
        считается факт оплаты Покупателем заказа на условиях настоящего
        Договора, в сроки и по ценам, указанным на интернет-сайте Продавца.
      </p>
      <p :class="$style.content__title">Понятия и определения</p>
      <p :class="$style.content__text">
        2.1. В этой оферте, если контекст не требует иного, нижеприведенные
        термины имеют следующие значения: «Товар» - электронные или физические
        товары, услуги; «Интернет-магазин» - в соответствии с Законом Украины
        «Об электронной коммерции», средство для представления или реализации
        товара, работы или услуги путем осуществления электронной сделки.
        «Продавец» - компания, которая реализует товары, представленные на
        Интернет-сайте. «Покупатель» - физическое лицо, заключившее с Продавцом
        Договор на условиях, изложенных ниже. «Заказ» - выбор отдельных позиций
        из перечня товаров, указанных Покупателем при размещении заказа и
        проведении оплаты.
      </p>
      <p :class="$style.content__title">Предмет договора</p>
      <p :class="$style.content__text">
        3.1. Продавец обязуется передать в собственность Покупателя товар, а
        Покупатель обязуется оплатить и принять товар на условиях настоящего
        Договора. Настоящий Договор регулирует куплю-продажу товаров в
        Интернет-магазине, в том числе: - добровольный выбор Покупателем товаров
        в Интернет-магазине; - самостоятельное оформление Покупателем заказа в
        Интернет-магазине; - оплата Покупателем заказа, оформленного в
        Интернет-магазине; - обработка и доставка заказа Покупателю в
        собственность на условиях настоящего Договора.
      </p>
      <p :class="$style.content__title">Оформление заказа</p>
      <p :class="$style.content__text">
        4.1. Покупатель вправе оформить заказ на любой товар, представленный на
        Сайте Интернет-магазина и имеющийся. <br />
        4.2. Порядок оформления заказа прописан пошагово в разделе Покупателям
        <a :class="$style.link" href="https://invest-factory.org/">
          https://invest-factory.org/
        </a>
      </p>
      <p :class="$style.content__title">Доставка товара</p>
      <p :class="$style.content__text">
        5.1. Доставка электронных товаров осуществляется автоматически на
        указанную электронную почту сразу после оплаты заказа. <br />
        5.2. Доставка физических товаров осуществляется выбранной Покупателем
        курьерской службой из представленных на сайте.
      </p>
      <p :class="$style.content__title">Условия доставки товара</p>
      <p :class="$style.content__text">
        6.1. Вместе с заказом Покупателю предоставляются документы согласно
        законодательству Украины.
      </p>
      <p :class="$style.content__title">Права и обязанности сторон</p>
      <p :class="$style.content__text">
        7.1. Продавец имеет право: - в одностороннем порядке прекратить
        предоставление услуг по настоящему договору в случае нарушения
        Покупателем условий настоящего договора. <br />
        7.2. Покупатель обязан: - своевременно оплатить и получить заказ на
        условиях настоящего договора. <br />
        7.3. Покупатель имеет право: - оформить заказ в Интернет-магазине; -
        оформить электронный договор; - требовать от продавца выполнения условий
        настоящего Договора.
      </p>
      <p :class="$style.content__title">Ответственность сторон</p>
      <p :class="$style.content__text">
        8.1. Стороны несут ответственность за невыполнение или ненадлежащее
        выполнение условий настоящего договора в порядке, предусмотренном
        настоящим договором и действующим законодательством Украины. <br />
        8.2. Продавец не несет ответственности за: - за незначительную
        несоответствие цветовой гаммы товара может отличаться от оригинала
        товара исключительно через разный цвет передачи мониторов персональных
        компьютеров отдельных моделей; - за содержание и достоверность
        информации, предоставленной Покупателем при оформлении заказа; - за
        задержку и перебои в предоставлении услуг (обработки заказа и доставки
        товара), которые происходят по причинам, находящимся вне сферы его
        контроля; - за противоправные незаконные действия, совершенные
        Покупателем с помощью данного доступа к сети Интернет; - за передачу
        Покупателем своих сетевых идентификаторов - IP, MAC-адреса, логина и
        пароля третьим лицам; <br />
        8.3. Покупатель, используя предоставленный ему доступ к сети Интернет,
        самостоятельно несет ответственность за вред, причиненный его действиями
        (лично, даже если под его логином находилось другое лицо) лицам или их
        имуществу, юридическим лицам, государству или нравственным принципам
        нравственности. <br />
        8.4. В случае наступления обстоятельств непреодолимой силы, стороны
        освобождаются от выполнения условий настоящего договора. Под
        обстоятельствами непреодолимой силы для целей настоящего договора
        понимаются события, имеющие чрезвычайный, непредвиденный характер,
        исключающих или объективно мешают выполнению этого договора, наступление
        которых Стороны не могли предвидеть и предотвратить разумными
        средствами.
        <br />
        8.5. Стороны прилагают максимум усилий для решения любых разногласий
        исключительно путем переговоров.
      </p>
      <p :class="$style.content__title">
        Защита персональных данных и прочие условия
      </p>
      <p :class="$style.content__text">
        9.1. Интернет-сайт оставляет за собой право в одностороннем порядке
        вносить изменения к этому договору при условии публикации его на сайте
        <a :class="$style.link" href="https://invest-factory.org/">
          https://invest-factory.org/
        </a>
        <br />
        9.2. Покупатель несет ответственность за достоверность информации,
        указанной при оформлении заказа. При этом, при осуществлении акцепта
        (оформлении заказа и последующей оплаты товара) Покупатель предоставляет
        Продавцу свое безоговорочное согласие на сбор, обработку, хранение,
        использование своих персональных данных, в понимании Закона Украины «О
        защите персональных данных». <br />
        9.3. Оплата Покупателем оформленного в Интернет-магазине заказ означает
        полное согласие Покупателя с условиями договора купли-продажи (публичной
        оферты) <br />
        9.4. Фактической датой электронного соглашения между сторонами является
        дата принятия условий, в соответствии со ст. 11 Закона Украины «Об
        электронной коммерции» <br />
        9.5. Использование ресурса Интернет-магазина для просмотра товара, а
        также для оформления заказа для Покупателя является бесплатным. <br />
        9.6. Информация, предоставляемая Покупателем является конфиденциальной.
        Интернет-магазин использует информацию о Покупателя исключительно в
        целях обработки заказа, отправка сообщений Покупателю, доставки товара,
        осуществление взаиморасчетов и тому подобное.
      </p>
      <p :class="$style.content__title">Порядок возврата товаров</p>
      <p :class="$style.content__text">
        10.1. Возврат товара в Интернет-магазин проводится согласно действующему
        законодательству Украины. <br />
        10.2. Возврат товара в Интернет-магазин проводится за счет Покупателя.
        <br />
        10.3. Электронные товары возврату или обмену не подлежат.
      </p>
      <p :class="$style.content__title">Срок действия договора</p>
      <p :class="$style.content__text">
        11.1. Электронный договор считается заключенным с момента получения
        лицом направившим предложение заключить такой договор, ответа о принятии
        этого предложения в порядке, определенном частью шестой статьи 11 Закона
        Украины "Об электронной коммерции". <br />
        11.2. До окончания срока действия настоящего Договора может быть
        расторгнут по взаимному согласию сторон до момента фактической доставки
        товара, путем возврата денежных средств <br />
        11.3. Стороны имеют право расторгнуть настоящий договор в одностороннем
        порядке, в случае невыполнения одной из сторон условий настоящего
        Договора и в случаях, предусмотренных действующим законодательством
        Украины.
      </p>
    </div>
  </section>
</template>

<script>
import Title from '@/components/basic/Title'
export default {
  components: {
    Title
  }
}
</script>

<style lang="scss" module>
@import '@/assets/styles/mixins.scss';
@import '@/assets/styles/colors.scss';
@import '@/assets/styles/breakpoints.scss';

.contract {
  position: relative;
  max-width: 88rem;
  margin: 0 auto;
  padding: 5.5rem 7.5rem;

  .arrow {
    position: absolute;
    top: 5.5rem;
    left: 10%;
    width: 1.5rem;
    cursor: pointer;
    transform: rotate(-90deg);
  }

  .subtitle {
    margin: 1rem 0;
    @include subtitle();
    font-weight: 400;
  }

  .content {
    &__title {
      margin: 0.5rem 0;
      @include text();
      font-weight: 700;
    }

    &__text {
      @include text();
      line-height: 1.25rem;

      .link {
        color: $blue;
        text-decoration: none;
      }
    }
  }
}

@include for-tablet {
  .contract {
    padding: 3rem 1.5rem;

    .arrow {
      top: 3rem;
      left: 5%;
    }
  }
}

@include for-mobile {
  .contract {
    .arrow {
      top: 0.5rem;
      left: 2rem;
      width: 1rem;
    }
  }
}
</style>
